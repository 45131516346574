import React, { useState } from "react";
import "./Navbar.scss";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import { NavLink } from "react-router-dom";
import Menu from "../../assets/icons/hamburger.svg";
import Close from "../../assets/icons/close.svg";

function Navbar() {
    // States
    const [navToggle, setNavToggle] = useState(false);

    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
            body.classList.remove(scrollUp);
            return;
        }

        if (
            currentScroll > lastScroll &&
            !body.classList.contains(scrollDown)
        ) {
            // down
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (
            currentScroll < lastScroll &&
            body.classList.contains(scrollDown)
        ) {
            // up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    });

    function navClose() {
        setNavToggle(false);
    }

    return (
        <div className="navbar trigger-menu-wrapper">
            <NavLink to="/" className="nav-logo">
                <h2>Alwin's Blog</h2>
            </NavLink>
            <div className="nav">
                <div className="menu-container">
                    <img
                        className="menu-btn"
                        src={Menu}
                        alt="Menu"
                        onClick={() => {
                            setNavToggle(true);
                        }}
                    ></img>
                </div>
                {navToggle ? (
                    <div className="nav-mobile">
                        <div className="close-container">
                            <img
                                className="close-btn"
                                src={Close}
                                alt="close"
                                onClick={navClose}
                            ></img>
                        </div>
                        <ul className="nav-items-mobile">
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="active-link"
                                    className="nav-link"
                                    to="/"
                                    onClick={navClose}
                                    exact
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="active-link"
                                    className="nav-link"
                                    to="/blog"
                                    onClick={navClose}
                                    exact
                                >
                                    Blogs
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    activeClassName="active-link"
                                    className="nav-link"
                                    to="/contact"
                                    onClick={navClose}
                                    exact
                                >
                                    Contact
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="https://alwinsunil.tk"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Portfolio
                                </a>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className="nav-link-btn"
                                    to="/subscribe"
                                >
                                    <button disabled>Subscribe</button>
                                </NavLink>
                            </li>
                            <li className="theme-mobile">
                                <p className="theme-mobile-title">
                                    Theme Switcher
                                </p>
                                <ThemeSwitcher />
                            </li>
                        </ul>
                    </div>
                ) : null}

                <ul className="nav-items">
                    <li className="nav-item">
                        <NavLink
                            activeClassName="active-link"
                            className="nav-link"
                            to="/"
                            exact
                        >
                            Home
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            activeClassName="active-link"
                            className="nav-link"
                            to="/blog"
                            exact
                        >
                            Blogs
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            activeClassName="active-link"
                            className="nav-link"
                            to="/contact"
                            exact
                        >
                            Contact
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            href="https://alwinsunil.tk"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Portfolio
                        </a>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link-btn" to="/subscribe">
                            <button disabled>Subscribe</button>
                        </NavLink>
                    </li>
                    <li>
                        <ThemeSwitcher />
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;

import React from "react";
import AnimContainer from "../AnimContainer/AnimContainer";
import "./LoadingAnimation.scss";

function LoadingAnimation() {
    return (
        <div className="loading-anim">
            <AnimContainer value="Loading..." />
        </div>
    );
}

export default LoadingAnimation;

import React, { useEffect } from "react";
import ResetNavbar from "../../NavbarResetter";
import HeroProfile from "../../assets/img/hero-profile.webp";
import Progress from "../../assets/icons/progress.svg";
import "./Hero.scss";

function Hero() {
    useEffect(() => {
        ResetNavbar();
    }, []);

    return (
        <div className="hero">
            <div className="hero-main">
                <h2 className="hero-title">Alwin's Blog.</h2>
                <p className="hero-content">
                    Hey this is my Blog, where I share my thoughts and talk
                    about tech and life.
                </p>
                <div className="hero-tags-container">
                    <div className="hero-tag">
                        <p>Life</p>
                    </div>
                    <div className="hero-tag">
                        <p>Tech</p>
                    </div>
                </div>
                <div className="hero-form-container">
                    <div className="hero-form">
                        <form action="/" className="hero-form-disabled">
                            <input
                                className="hero-email"
                                type="email"
                                placeholder="Your email address"
                                required
                                disabled
                            ></input>
                            <input
                                className="hero-submit"
                                type="submit"
                                value="Subscribe"
                                disabled
                            ></input>
                        </form>
                    </div>
                    <div className="disabled-warning"></div>
                    <div className="progress">
                        <object
                            className="progress-img"
                            type="image/svg+xml"
                            data={Progress}
                        >
                            svg
                        </object>
                        <p className="progress-text">work in progress...</p>
                    </div>
                </div>
            </div>
            <div className="hero-img">
                <img src={HeroProfile} alt="Alwin Sunil"></img>
            </div>
        </div>
    );
}

export default Hero;

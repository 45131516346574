import React, { useState, useEffect } from "react";
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import { NavLink } from "react-router-dom";
import { getFirebase } from "../../Firebase";
import "./LatestBlogs.scss";

function LatestBlogs() {
    const [loading, setLoading] = useState(true);
    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        if (loading && !blogPosts.length) {
            getFirebase()
                .database()
                .ref("/")
                .orderByChild("dateFormatted")
                .limitToFirst(4)
                .once("value")
                .then((snapshot) => {
                    let posts = [];
                    const snapshotVal = snapshot.val();
                    for (let slug in snapshotVal) {
                        posts.push(snapshotVal[slug]);
                    }
                    const newestFirst = posts;
                    setBlogPosts(newestFirst);
                    setLoading(false);
                });
        }
    }, [loading, blogPosts.length]);

    if (loading) {
        return <LoadingAnimation />;
    }

    return (
        <div className="latest-blogs">
            <h2 className="latest-title">Latest Blogs</h2>
            <div className="latest-blogs-container">
                {blogPosts.map((blogPost) => (
                    <NavLink key={blogPost.slug} to={`/${blogPost.slug}`}>
                        <div
                            className="blog-element"
                            style={{ backgroundImage: `url(${blogPost.img})` }}
                        >
                            <div className="blog-details">
                                <p className="blog-date">
                                    {blogPost.datePretty}
                                </p>
                                <h2 className="blog-title">{blogPost.title}</h2>
                            </div>
                        </div>
                    </NavLink>
                ))}
            </div>
            <div className="blog-btn-container">
                <NavLink to="/blog" className="blog-btn-link">
                    <button className="blog-btn">See All</button>
                </NavLink>
            </div>
        </div>
    );
}

export default LatestBlogs;

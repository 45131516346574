import React, { useState } from "react";
import firebase from "firebase/app";
import AnimContainer from "../AnimContainer/AnimContainer";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Success from "../../assets/icons/success.svg";
import Err from "../../assets/icons/err.svg";
import "./MessageForm.scss";

function MessageForm() {
    // States
    const [submitAnim, setSubmitAnim] = useState(false);
    const [form, setForm] = useState(true);
    const [messageSuccess, setmessageSuccess] = useState(null);
    const [messageErr, setmessageErr] = useState(null);

    const [nameDoc, setNameDoc] = useState([]);
    const [emailDoc, setEmailDoc] = useState([]);
    const [messageDoc, setMessageDoc] = useState([]);

    const db = firebase.firestore();

    function handleSubmit(e) {
        e.preventDefault();
        setForm(null);
        setSubmitAnim(true);
        db.collection("messages")
            .add({
                name: nameDoc,
                email: emailDoc,
                message: messageDoc,
            })
            .then(function () {
                setSubmitAnim(null);
                setmessageSuccess(true);
            })
            .catch(function () {
                setSubmitAnim(null);
                setmessageErr(true);
            });
    }

    function reloadPage() {
        window.location.reload();
    }

    return (
        <div>
            {form ? (
                <div className="contact-form">
                    <p className="form-content">
                        Feel free 😊 to message me if you have any queries.
                    </p>
                    <p className="form-content-end">
                        Follow me 🧑 on social media to get in touch.
                    </p>
                    <form
                        className="contact-form-element"
                        onSubmit={handleSubmit}
                    >
                        <label className="contact-label">Name</label>
                        <input
                            value={nameDoc}
                            onChange={(e) => setNameDoc(e.target.value)}
                            className="contact-input"
                            type="text"
                            required
                        ></input>
                        <label className="contact-label">Email</label>
                        <input
                            value={emailDoc}
                            onChange={(e) => setEmailDoc(e.target.value)}
                            className="contact-input"
                            type="email"
                            required
                        ></input>
                        <label className="contact-label">
                            Type in your message.
                        </label>
                        <TextareaAutosize
                            value={messageDoc}
                            onChange={(e) => setMessageDoc(e.target.value)}
                            className="contact-input message-input"
                            type="text"
                            required
                            aria-label="minimum height"
                            rowsMin={5}
                        />
                        <input
                            className="submit-btn"
                            type="submit"
                            value="Submit"
                        ></input>
                    </form>
                </div>
            ) : null}

            {submitAnim ? (
                <div className="submit-anim">
                    <AnimContainer value="Submiting..." />
                </div>
            ) : null}

            {messageSuccess ? (
                <div className="MessageSubmit">
                    <img src={Success} alt=""></img>
                    <h3>Message Sent.</h3>
                    <button className="btn" onClick={reloadPage}>
                        Send Another Message
                    </button>
                </div>
            ) : null}

            {messageErr ? (
                <div className="MessageSubmit">
                    <img className="err-img" src={Err} alt=""></img>
                    <h3>Message Couldn't Send.</h3>
                    <button className="btn" onClick={reloadPage}>
                        Try Again
                    </button>
                </div>
            ) : null}
        </div>
    );
}

export default MessageForm;

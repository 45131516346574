import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import PageNoFoundImg from "../../assets/img/leakinwebsite.webp";
import ResetNavbar from "../../NavbarResetter";
import "./PageNotFound.scss";

function PageNotFound() {
    useEffect(() => {
        ResetNavbar();
    }, []);

    return (
        <div className="pagenotfound">
            <div className="notfound-content">
                <p className="notfound-caption">
                    There’s a Leak in the Website.
                </p>
                <h1>404</h1>
                <p>This Page doesn't exist or have been removed.</p>
                <NavLink to="/">
                    <button className="btn goback-btn">Go to Home</button>
                </NavLink>
            </div>
            <div className="notfound-img">
                <img
                    className="pagenotfound-img"
                    src={PageNoFoundImg}
                    alt=""
                ></img>
            </div>
        </div>
    );
}

export default PageNotFound;

import React from "react";
import "./AnimContainer.scss";

function AnimContainer(props) {
    return (
        <div className="anim-container">
            <div className="sk__fading__circle">
                <div className="sk__circle1 sk__circle"></div>
                <div className="sk__circle2 sk__circle"></div>
                <div className="sk__circle3 sk__circle"></div>
                <div className="sk__circle4 sk__circle"></div>
                <div className="sk__circle5 sk__circle"></div>
                <div className="sk__circle6 sk__circle"></div>
                <div className="sk__circle7 sk__circle"></div>
                <div className="sk__circle8 sk__circle"></div>
                <div className="sk__circle9 sk__circle"></div>
                <div className="sk__circle9 sk__circle"></div>
                <div className="sk__circle10 sk__circle"></div>
                <div className="sk__circle11 sk__circle"></div>
                <div className="sk__circle12 sk__circle"></div>
            </div>
            {props ? <p>{props.value}</p> : null}
        </div>
    );
}

export default AnimContainer;

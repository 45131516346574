import React, { useState, useEffect } from "react";
import { NavLink, Redirect } from "react-router-dom";
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs";
import AnimContainer from "../../components/AnimContainer/AnimContainer";
import Share from "../../components/Share/Share";
import { getFirebase } from "../../Firebase";
import ResetNavbar from "../../NavbarResetter";
import Profile from "../../assets/img/profile.webp";
import "./BlogPage.scss";

function BlogPage({ match }) {
    ResetNavbar();

    const slug = match.params.slug;
    const [loading, setLoading] = useState(true);
    const [currentPost, setCurrentPost] = useState();

    useEffect(() => {
        if (loading && !currentPost) {
            getFirebase()
                .database()
                .ref(`/${slug}/`)
                .once("value")
                .then((snapshot) => {
                    const post = snapshot.val();
                    setCurrentPost(post);
                    setLoading(false);
                });
        }
    }, [loading, currentPost, slug]);

    if (loading) {
        return (
            <div className="loading-anim blog-anim-header">
                <AnimContainer value="Loading..." />
            </div>
        );
    }

    const postDoesNotExist = !currentPost;
    if (postDoesNotExist) {
        return <Redirect to="/404" />;
    }

    return (
        <div>
            <article>
                <div className="blog-header">
                    <div className="blog-info">
                        <h2>{currentPost.title}</h2>
                        <div className="blog-tags">
                            <NavLink to="/contact">
                                <div className="profile">
                                    <img
                                        className="profile-img"
                                        src={Profile}
                                        alt="Alwin Sunil"
                                    ></img>
                                    <p className="profile-id">Alwin Sunil | </p>
                                </div>
                            </NavLink>
                            <p className="datePretty">
                                {currentPost.datePretty}
                            </p>
                        </div>
                    </div>
                    <img
                        className="blog-img"
                        src={currentPost.img}
                        alt={currentPost.imgAlt}
                    ></img>
                </div>
                <div
                    className="blog-content"
                    key={currentPost.slug}
                    dangerouslySetInnerHTML={{ __html: currentPost.content }}
                ></div>
            </article>
            <Share />
            <LatestBlogs />
        </div>
    );
}

export default BlogPage;

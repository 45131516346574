import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import ResetNavbar from "./NavbarResetter.js";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import FullPageLoader from "./components/FullPageLoader/FullPageLoader";
import Home from "./Pages/Home/Home";
import Blogs from "./Pages/Blog/Blog";
import BlogPage from "./Pages/BlogPage/BlogPage";
import Contact from "./Pages/Contact/Contact";
import "./App.scss";

function App() {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(null);
        ResetNavbar();
    }, []);

    if (loader) {
        return <FullPageLoader />;
    }

    return (
        <div className={loader ? "app-loader" : "app"}>
            <Navbar />
            <Switch>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route path="/blog" exact>
                    <Blogs />
                </Route>
                <Route path="/404" component={PageNotFound} exact />
                <Route path="/contact" component={Contact} exact />
                <Route path="/:slug" component={BlogPage} exact />
                <Route component={PageNotFound} exact />
            </Switch>
            <Footer />
        </div>
    );
}

export default App;

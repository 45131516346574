import React from "react";
import "../../components/LoadingAnimation/LoadingAnimation.scss";
import AnimContainer from "../AnimContainer/AnimContainer";
import "./FullPageLoader.scss";

function FullPageLoader() {
    return (
        <div>
            <div className="loading-animation loading-container">
                <AnimContainer />
            </div>
        </div>
    );
}

export default FullPageLoader;

import React, { useEffect } from "react";
import ResetNavbar from "../../NavbarResetter";
import MessageForm from "../../components/MessageForm/MessageForm";
import "./Contact.scss";

function Contact() {
    useEffect(() => {
        ResetNavbar();
    }, []);

    return (
        <div className="contact">
            <div className="contact-header">
                <h2 className="contact-title">Contact</h2>
            </div>
            <MessageForm />
        </div>
    );
}

export default Contact;

import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import ResetNavbar from "../../NavbarResetter";
import { ReactComponent as InstagramLogo } from "../../assets/icons/instagram.svg";
import { ReactComponent as TwitterLogo } from "../../assets/icons/twitter.svg";
import "./Footer.scss";

function Footer() {
    useEffect(() => {
        ResetNavbar();
    }, []);

    return (
        <div className="footer">
            <div className="app-icons">
                <a
                    className="app-links"
                    href="https://bit.ly/3neuv5t"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <InstagramLogo className="footer-icons" />
                </a>
                <a
                    className="app-links margin-right margin-null"
                    href="https://bit.ly/2PaLSrk"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <TwitterLogo className="footer-icons" />
                </a>
            </div>
            <div className="footer-end">
                <p className="footer-info">
                    © 2021&nbsp;
                    <a
                        className="footer-portfolio-link"
                        href="https://alwinsunil.tk"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Alwin Sunil
                    </a>
                </p>
            </div>
            <div className="footer-links">
                <ul className="footer-links-ul margin-left margin-null">
                    <li className="footer-link">
                        <NavLink to="/" className="footer-link-a" exact>
                            Home
                        </NavLink>
                    </li>
                    <li className="footer-link">
                        <NavLink to="/blog" className="footer-link-a" exact>
                            Blog
                        </NavLink>
                    </li>
                    <li className="footer-link padding-null">
                        <NavLink to="/contact" className="footer-link-a" exact>
                            Contact
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer;

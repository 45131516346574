import React, { useEffect } from "react";
import "./Home.scss";
import Hero from "../../components/Hero/Hero";
import LatestBlogs from "../../components/LatestBlogs/LatestBlogs";
import ResetNavbar from "../../NavbarResetter";

function Home() {
    useEffect(() => {
        ResetNavbar();
    }, []);

    return (
        <div className="home">
            <Hero />
            <LatestBlogs />
        </div>
    );
}

export default Home;

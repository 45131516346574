import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AnimContainer from "../../components/AnimContainer/AnimContainer";
import { getFirebase } from "../../Firebase";
import ResetNavbar from "../../NavbarResetter";
import "./Blog.scss";

function Blog() {
    const [loading, setLoading] = useState(true);
    const [blogPosts, setBlogPosts] = useState([]);

    ResetNavbar();

    useEffect(() => {
        if (loading && !blogPosts.length) {
            getFirebase()
                .database()
                .ref("/")
                .orderByChild("dateFormatted")
                .once("value")
                .then((snapshot) => {
                    let posts = [];
                    const snapshotVal = snapshot.val();
                    for (let slug in snapshotVal) {
                        posts.push(snapshotVal[slug]);
                    }
                    const newestFirst = posts;
                    setBlogPosts(newestFirst);
                    setLoading(false);
                });
        }
    }, [loading, blogPosts.length]);

    return (
        <div className="blogs">
            <div className="blogs-title-container">
                <h2 className="blogs-title">Blog</h2>
            </div>
            {loading ? (
                <div className="loading-anim blog-anim">
                    <AnimContainer value="Loading..." />
                </div>
            ) : (
                <div className="blogs-container">
                    {blogPosts.map((blogPost) => (
                        <NavLink key={blogPost.slug} to={`/${blogPost.slug}`}>
                            <div
                                className="blog-element"
                                style={{
                                    backgroundImage: `url(${blogPost.img})`,
                                }}
                            >
                                <div className="blog-details">
                                    <p className="blog-date">
                                        {blogPost.datePretty}
                                    </p>
                                    <h2 className="blog-title">
                                        {blogPost.title}
                                    </h2>
                                </div>
                            </div>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Blog;

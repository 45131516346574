import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import "./Share.scss";

function Share() {
    const link = [window.location.href];

    function share() {
        alert("Link copied to clipboard 👍\r\nShare this blog.");
    }

    return (
        <div className="share">
            <div className="share-container">
                <h3>Share this Post</h3>
                <div className="share-btn-container">
                    <CopyToClipboard text={link}>
                        <IconButton className="share-btn" onClick={share}>
                            <InsertLinkIcon
                                className="share-icon"
                                fontSize="default"
                            />
                        </IconButton>
                    </CopyToClipboard>

                    {/*  Share to Facebook */}
                    <a
                        className=""
                        href={`https://facebook.com/sharer/sharer.php?u=${link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on Facebook"
                    >
                        <IconButton className="facebook share-btn">
                            <FacebookIcon
                                className="share-icon"
                                fontSize="default"
                            />
                        </IconButton>
                    </a>

                    {/* Share to Twitter */}
                    <a
                        className="sharing-button__link"
                        href={`https://twitter.com/intent/tweet/?text=Alwins%20Blog.&url=${link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on Twitter"
                    >
                        <IconButton className="twitter share-btn">
                            <TwitterIcon
                                className="share-icon"
                                fontSize="default"
                            />
                        </IconButton>
                    </a>

                    {/*  Share to WhatsApp */}
                    <a
                        className=""
                        href={`whatsapp://send?text=Alwin's%20Blog.%20${link}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Share on WhatsApp"
                    >
                        <IconButton className="whatsapp share-btn">
                            <WhatsAppIcon
                                className="share-icon"
                                fontSize="default"
                            />
                        </IconButton>
                    </a>
                </div>
            </div>
            <p className="adblocker-warning">
                *If sharing options are not visible, try disabling Adblocker.
            </p>
        </div>
    );
}

export default Share;

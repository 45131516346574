import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCA9bIK0OPIBsU-PfplxP3K0R6RgFDrVw0",
    authDomain: "blog-1c261.firebaseapp.com",
    projectId: "blog-1c261",
    storageBucket: "blog-1c261.appspot.com",
    messagingSenderId: "572420809594",
    appId: "1:572420809594:web:fe76e840fb5e93c33a6cc3",
    measurementId: "G-2L26TQGYJ8",
};

firebase.initializeApp(firebaseConfig);

firebase.analytics();

let firebaseCache;

export const getFirebase = () => {
    if (firebaseCache) {
        return firebaseCache;
    }
    firebaseCache = firebase;
    return firebase;
};
